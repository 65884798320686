import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from 'components/global/layout'
import LegalWrapper from 'components/legal/legalWrapper'
import PageHeader from 'components/shared/pageHeader'
import BackToHome from 'components/global/backToHome'

const CookiePolicy = ({ data }) => {
  const mob = data.mobHeaderImg
  const desk = data.deskHeaderImg
  const meta = {
    title: 'Cookie Policy | The Mousetrap Tour',
  }
  return (
    <Layout title={meta.title}>
      <Link to="/">
        <span className="sr-only">CLICK HERE</span>
        <PageHeader mobileHeader={mob} desktopHeader={desk} />
      </Link>
      <LegalWrapper title="Cookie Policy">
        <BackToHome />
        <h2>Cookies and how they benefit you:</h2>
        <p>
          Our website uses cookies, as almost all websites do, to help provide
          you with the best experience we can. Cookies are small text files that
          are placed on your computer or mobile phone when you browse websites.
        </p>
        <p>
          To read our Privacy Policy please&nbsp;
          <u>
            <Link to="/privacy-policy">here</Link>
          </u>
          .
        </p>
        <h3>THE MOUSETRAP uses cookies for three main purposes:</h3>
        <ul>
          <li>To ensure the website works/functions quickly and efficiently</li>
          <li>
            To monitor website performance and help us make improvements in the
            future
          </li>
          <li>
            To tailor our marketing to communicate more effectively through
            online advertising.
          </li>
        </ul>
        <h2>
          <strong>Our cookies help us:</strong>
        </h2>
        <ul>
          <li>Make our website work as you&rsquo;d expect</li>
          <li>Save you having to login every time you visit the site</li>
          <li>Remember your settings during and between visits</li>
          <li>Improve the speed/security of the site</li>
          <li>Allow you to share pages with social networks like Facebook</li>
          <li>Continuously improve our website for you</li>
          <li>Make our marketing more efficient</li>
        </ul>
        <h2>
          <strong>We do not use cookies to:</strong>
        </h2>
        <ul>
          <li>Collect any sensitive information</li>
          <li>Pass personally identifiable data to third parties</li>
          <li>Pay sales commissions</li>
        </ul>
        <p>You can learn more about the cookies we use below:</p>
        <h2>
          <strong>Google Analytics</strong>
        </h2>
        <p>
          This monitors how people use our site &ndash; for example the browser
          they use and the pages they visit. It&rsquo;s used to assess our
          website&rsquo;s performance and to help us plan improvements.
        </p>
        <h2>
          <strong>Session</strong>
        </h2>
        <p>
          This cookie is a transient cookie (it is erased when you close the
          browser). It acts as a small amount of memory to remember what
          you&rsquo;ve done on past pages.
        </p>
        <h2>
          <strong>Granting us permission to use cookies</strong>
        </h2>
        <p>
          If the settings on your software that you are using to view this
          website (your browser) are adjusted to accept cookies we take this,
          and your continued use of our website, to mean that you are fine with
          this. Should you wish to remove or not use cookies from our site you
          can learn how to do this below; however, doing so will likely mean
          that our site will not work as you would expect.
        </p>
        <h2>
          <strong>Our own cookies</strong>
        </h2>
        <p>We use cookies to make our website work including:</p>
        <ul>
          <li>Remembering your search settings&nbsp;</li>
        </ul>
        <h2>
          <strong>Third party functions</strong>
        </h2>
        <p>
          Our site, like most websites, includes functionality provided by third
          parties. A common example is an embedded YouTube video. Disabling
          these cookies will likely break the functions offered by these third
          parties.
        </p>
        <h2>
          <strong>Anonymous visitor statistics cookies</strong>
        </h2>
        <p>
          We use cookies to compile visitor statistics such as how many people
          have visited our website, what type of technology they are using
          (e.g., Mac or Windows, which helps to identify when our site
          isn&rsquo;t working as it should for particular technologies), how
          long they spend on the site, what page they look at etc. This helps us
          to continuously improve our website. These so called
          &ldquo;analytics&rdquo; programs also tell us if, on an anonymous
          basis, how people reached this site (e.g. from a search engine) and
          whether they have been here before; this helps us to put more money
          into developing our services for you instead of marketing spend.
        </p>
        <h2>
          <strong>We use advertising cookies</strong>
        </h2>
        <p>
          Cookies are widely used in online advertising. Neither us, advertisers
          or our advertising partners can gain personally identifiable
          information from these cookies. We only work with advertising partners
          who work to accepted privacy standards such as&nbsp;
          <a href="https://www.youronlinechoices.com/uk/">
            https://www.youronlinechoices.com/uk/
          </a>
        </p>
        <p>
          You can learn more about online advertising at&nbsp;
          <a href="https://www.youronlinechoices.com/">
            https://www.youronlinechoices.com\
          </a>
          . You can opt-out of almost all advertising cookies at&nbsp;
          <a href="https://www.youronlinechoices.com/uk/your-ad-choices">
            https://www.youronlinechoices.com/uk/your-ad-choices
          </a>
          &nbsp;although we would prefer that you didn&rsquo;t, as ultimately
          advertising helps keep much of the internet free. It is also worth
          noting that opting out of advertising cookies will not mean you
          won&rsquo;t see adverts, just simply that they won&rsquo;t be tailored
          to you any longer.
        </p>
        <p>You can learn more about the cookies we use below:</p>
        <h2>
          <strong>Google Campaign Manager 360 Floodlights</strong>
        </h2>
        <p>
          You may notice that sometimes after visiting our site you see
          increased numbers of adverts from us when browsing the web. The
          technology to allow us to do this is made possible by a Google
          Floodlight tag. The Floodlight allows us to track and report
          on&nbsp;conversions&nbsp;&mdash; the actions of users who visit our
          site after viewing or clicking on one of our ads &mdash; and to set up
          an&nbsp;audience, which compiles lists of users who've performed
          specific actions on a site, then makes those users available for
          targeting by subsequent campaigns. Don&rsquo;t worry - we are unable
          to proactively reach out to you as the whole process is entirely
          anonymised.
        </p>
        <p>
          Floodlights help us understand how well our online adverts are
          performing by allowing us to see whether people who have seen our ads
          visit our website.
        </p>
        <p>
          For more information, you can read about{' '}
          <a href="https://support.google.com/campaignmanager/answer/2839090">
            how Google Marketing Platform advertising products and Google Ad
            Manager&nbsp;use&nbsp;cookies
          </a>
          , see their{' '}
          <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>,
          and learn how you can{' '}
          <a href="https://www.google.com/ads/preferences">
            opt out of ads personalization
          </a>
          <u>.</u>
        </p>
        <h2>
          <strong>Facebook</strong>
        </h2>
        <p>
          Facebook cookies enable us to understand if you complete certain
          actions on our website(s) after you have seen or clicked through one
          of our advertisements served on Facebook, Instagram, or a partner in
          Facebook&rsquo;s advertising network. Facebook uses this cookie to
          understand the content with which you have engaged on our website(s)
          so they may subsequently deliver some targeted advertisements to you.{' '}
          <a href="https://www.facebook.com/help/568137493302217">
            <strong>Learn More</strong>
          </a>
        </p>
        <h2>
          <strong>Google Tracking Cookies</strong>
        </h2>
        <p>
          Google tracking cookies enable us to understand if you complete
          certain actions on our website(s) after you have seen or clicked
          through one of our ads served via Google. Based on the content you
          have engaged with on our websites Google are able to deliver some
          targeted ads across other Google partner websites.{' '}
          <a href="https://support.google.com/ads/answer/2662922">
            <strong>Learn More</strong>
          </a>
        </p>
        <h2>
          <strong>Twitter</strong>
        </h2>
        <p>
          Twitter cookies enable us to understand if you complete certain
          actions on our website(s) after you have seen or clicked through one
          of our advertisements served on Twitter. Twitter uses this cookie to
          understand the content with which you have engaged on our website(s)
          so they may subsequently deliver some targeted advertisements to you.{' '}
          <a href="https://support.twitter.com/articles/20170410">
            <strong>Learn More</strong>
          </a>
        </p>
        <h2>
          <strong>TikTok</strong>
        </h2>
        <p>
          TikTok cookies enable us to understand if you complete certain actions
          on our website(s) after you have seen or clicked through one of our
          advertisements served on TikTok. TikTok uses this cookie to understand
          the content with which you have engaged on our website(s) so they may
          subsequently deliver some targeted advertisements to you.{' '}
          <a href="https://ads.tiktok.com/i18n/official/article?aid=623303076294509199">
            <strong>Learn More</strong>
          </a>
        </p>
        <h2>
          <strong>Quantcast</strong>
        </h2>
        <p>
          The Quantcast tag allows us to perform campaign reporting, optimize
          campaigns effectively and unlock valuable insights about website
          visitors that may come via the campaigns we run with Quantcast
          assisting with attribution and overall performance.{' '}
          <a href="https://www.quantcast.com/opt-out/">
            <strong>Learn More</strong>
          </a>
        </p>
        <h2>
          <strong>Managing cookies</strong>
        </h2>
        <p>
          Cookies cannot be used by themselves to identify you. You can easily
          control and disable cookies through your browser settings.
        </p>
        <p>
          You can usually switch cookies off by adjusting your browser settings
          to stop it from accepting cookies. Doing so, however, will likely
          limit the functionality of this site - and a large proportion of the
          world&rsquo;s websites - as cookies are a standard part of most modern
          websites.
        </p>
        <p>
          All browsers are different &ndash; for more information on cookies
          including opting out, visit the following external links:
        </p>
        <p>
          <a href="https://www.aboutcookies.org/">www.aboutcookies.org</a>
          <br />
          <a href="https://www.youronlinechoices.eu/">
            www.youronlinechoices.eu
          </a>
        </p>
        <p>
          To opt out of being tracked by Google Analytics across all websites
          visit&nbsp;
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
          .
        </p>
        <p>
          Please be aware that by limiting or deleting cookies, your experience
          of our website might not be the best it could be. If you have any
          questions about cookies, please contact the Data Protection Manager{' '}
          <a href="mailto:info@adamspiegel.com">info@adamspiegel.com</a>
        </p>
      </LegalWrapper>
    </Layout>
  )
}

export default CookiePolicy

export const TermsConditionsQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "giles-mob" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "giles" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
