import {Link} from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import HomeIcon from 'svgs/home.svg'
import {media} from 'utils/Media'

const BackToHomeStyles = styled.aside`
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 50%;
  right: 0;
  background-color: var(--main);
  width: 40px;
  height: 40px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      fill: var(--white);
      width: 25px;
      height: 25px;
      transition: var(--transition);

      &:hover,
      &:active {
        fill: var(--black);
      }
    }
  }

  @media ${media.md} {
    right: 100%;
    left: 0;
    width: 50px;
    height: 60px;
    top: 45%;
  }
`
const BackToHome = () => (
    <BackToHomeStyles>
        <Link to="/">
      <span>
        <span className="sr-only">CLICK TO RETURN TO THE HOME PAGE</span>
        <HomeIcon/>
      </span>
        </Link>
    </BackToHomeStyles>
)

export default BackToHome
